import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Demos from "../components/demos"
import Colors from "../variables/colors"
import Sizes from "../variables/sizes"
import Img from "gatsby-image"

const Intro = styled.section`
  min-height: 200px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  color: white;
  background-color: ${Colors.charcoal};
`

const Titles = styled.div`
  max-width: ${Sizes.text};
  `
  
  const Title = styled.h1`
  max-width: ${Sizes.desktop};
  margin: 0 auto 1em;
  font-size: 1.6em;
  line-height: 1.4em;
  text-align: left;
  color: ${Colors.orange_dk};
  `
  
  const SubTitle = styled.h2`
  max-width: ${Sizes.desktop};
  margin: 0 auto 1em;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.4em;
  `
  
  const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${Sizes.text};
  margin: 0 auto;
  padding: 2em;
` 

const StyledImg = styled(Img)`
    margin-left: 3em;
`

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Ably Labs" />
    <Intro>
      <Holder>
        <Titles>
          <Title>One stop shop for ready-made developer resources to get up and running with realtime messaging using Ably</Title>
          <SubTitle>Integrate with third party services, explore our starter kits and frameworks for the most common relatime use cases, customize and make them your own, or simply browse through our gallery of ready-to-use realtime apps with readily available guides and open-sourced code.</SubTitle>
        </Titles>
        <StyledImg fixed={data.file.childImageSharp.fixed} />
      </Holder>
    </Intro>
    
    <Demos/>
  </Layout>
)

export default IndexPage


export const query = graphql`
  query {
    file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fixed(width: 500, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`