import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Colors from "../variables/colors"
import Sizes from "../variables/sizes"

const Demo = styled.li`
    list-style: none;
    margin-bottom: 2em;
    & .gatsby-image-wrapper {
        flex-shrink: 0;
        display: block;
        width: 200px;
        height: 150px;
        margin-right: 1em;
        border-radius: .5em; 
    }
    
    & a:hover span {
        opacity: 0;
    }
`
    
const DemoLink = styled(Link)`
    display: flex;
    text-decoration: none;
    padding: 1em;
    border-radius: 1em; 
    background-color: white;
    box-shadow: 0px 9px 12px 0px rgba(0,0,0,0.2);
`

const Title = styled.h1`
    font-size: 1.4em;
    color: ${Colors.charcoal};
`
const Text = styled.p`
    position: relative;
    font-size: 1em;
    line-height: 1.4em;
    height: 3em;
    overflow: hidden;
    color: ${Colors.charcoal};
    
    &:before {
        content: "";
        float: left;
        width: 5px;
        height: 3em;
    }
    
    & > *:first-child {
        float: right;
        width: 100%;
        margin-left: -5px;
    }
    
    &:after {
        content: "...";
        position: absolute;
        top: 1.4em;
        right: 0;
        width: 4em;
        margin-left: -4em;
        padding-left: 1.5em;
        text-align: left;
        background-size: 100% 100%;/* 512x1 image,gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
        background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
        background: linear-gradient(to right,rgba(255,255,255,0),white 40%,white);
        color: ${Colors.charcoal};
        z-index: 1;
    }
`

const TagsHolder = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
`

const Tag = styled.li`
    width: max-content;
    height: max-content;
    margin-right: 1em;
    padding: 5px 2em;
    border-radius: .5em;
    list-style: none;
    color: white;
    background-color: ${Colors.orange};
    &[data-color="games"] {
        background-color:${Colors.tag1}
    }
    &[data-color="video"] {
        background-color:${Colors.tag2}
    }
`


const PostLink = ({ post }) => {
    const Tags =  post.frontmatter.tags.map((tag, i) => <Tag data-color={tag} key={post.frontmatter.date + i}>{tag}</Tag>)

    return (
    <Demo>
        <DemoLink to={post.frontmatter.slug}>
            <Img fluid={post.frontmatter.featureImage.childImageSharp.fluid} />
            <div>
                <Title>{post.frontmatter.title}</Title>
                <Text>{post.excerpt}</Text>
                <TagsHolder>
                    {Tags}
                </TagsHolder>
            </div>
        </DemoLink>
    </Demo>
    )
}

export default PostLink