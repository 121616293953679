import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import PostLink from "./post-link"

import Sizes from "../variables/sizes"

const DemosHolder = styled.ul`
  max-width: ${Sizes.desktop};
  padding: 2em;
  margin: 1em auto;
`

export default function Demos() {
  const data = useStaticQuery(graphql`{
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              intro
              tags
              featureImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }`);

  const Posts = data.allMarkdownRemark.edges.filter(edge => !!edge.node.frontmatter.date)
                    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
    return (
          <DemosHolder>
            <h1>Featured posts</h1>
            {Posts}
          </DemosHolder>
    )
}


